import React, {Fragment} from "react"
import Cassette from "../components/cassette"
import Headline from "../components/headline"


const TagArticles = ({ tag, posts }) => {
  const reg = new RegExp(tag)
  const title = `${tag} カテゴリーの記事一覧`

  return (
    <Fragment>
      <Headline str={title} />
        {posts.filter(n => { return n.node.frontmatter.description != null &&  n.node.frontmatter.description.match(reg)}).map(({ node }) => {
          return(<Cassette node={node} link={node.fields.slug.substring(1)} key={node.fields.slug} />)
        })}
    </Fragment>
  )
}

export default TagArticles
