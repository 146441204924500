import React from "react"

const Headline = ({str}) => {

  return (
    <h2
      style={{
        paddingLeft: '10px',
        paddingBottom: '10px',
        paddingTop: '10px',
        paddingRight: '10px',
        backgroundColor: '#eee',
        marginBottom: 10,
      }}
      dangerouslySetInnerHTML={{__html: str}}
    />
  )
}

export default Headline
