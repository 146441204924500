import React from "react"
import {Link} from "gatsby"

const ReadMoreButton = () => {

  return (
    <div style={{textAlign: "center"}}>
      <button type={"button"} style={{border: 0, margin: 20, padding: 10, width: "90%", color: "#eb6317"}}>
        <Link style={{textDecoration: "none"}} to={'/entry'}>
          <span style={{display: "block"}}>すべての記事</span>
        </Link>
      </button>
    </div>
  )
}

export default ReadMoreButton
