import React from "react"

import { rhythm } from "../utils/typography"
import Tags from "./tags"
import Thumb from "./thumb"

const Cassette = ({ node, link }) => {

  const title = node.frontmatter.title || node.fields.slug
  return(
    <article key={node.fields.slug} style={{ padding: "10px 0px", margin: "10px 0px" }}>
      <header>
        <a href={`/entry/${link}`} style={{ boxShadow: `none`, textDecoration: `none`, color: `#000` }}>
          <div>
            <section
              style={{
                marginBottom: 5,
                display: "inline-block",
                paddingLeft: 4,
                paddingRight: 4,
                backgroundColor: "#000",
                color: "#fff",
                fontSize: 10,
              }}
              dangerouslySetInnerHTML={{__html: node.frontmatter.date}}
            />
            <h3
              style={{
                marginBottom: rhythm(1 / 4),
                paddingLeft: '10px',
                paddingBottom: '10px',
                paddingTop: '10px',
                backgroundColor: '#eee',
                borderLeft: "10px solid #111",
              }}
              dangerouslySetInnerHTML={{__html: title}}
            />
          </div>
        </a>
        <Tags tags={ node.frontmatter.description } />
      </header>
      <section style={{margin: "5px 0px 0px 0px"}}>
        <a href={`/entry/${link}`} style={{ boxShadow: `none`, textDecoration: `none`, color: `#000` }}>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                flex: 1,
                display: 'inline-flex',
                alignSelf: 'flex-start',
                position: 'relative',
                paddingTop: 5
              }}
            >
              <Thumb hero={ node.frontmatter.hero } />
            </div>
            <div
              style={{
                flex: 2,
                display: 'inline-flex',
                alignSelf: 'flex-start',
                paddingLeft: 10,
                paddingRight: 10
              }}
              dangerouslySetInnerHTML={{__html: node.excerpt}}
            />
          </div>
        </a>
      </section>

  </article>
  )
}

export default Cassette
