import React from "react"
import Tag from "./tag"

const Tags = ({tags}) => {
  const str = tags ?  tags : "";

  if (str === ""  ) {
    return(
      <small></small>
    )
  } else {
    return(
      <small>
        {str === "" ? "" : "カテゴリー: "}
        { str.split(',').map( (tag) => {return ( <Tag tag={tag} key={tag} /> )} ) }
      </small>
    )
  }
}

export default Tags
