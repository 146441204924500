import React from "react"
import { Link } from "gatsby"

const Tag = ({tag}) => {
  tag = tag.trim( )

  return (
    <Link to={`/tag/${tag}`}>
      <button
        value={tag}
        style={{
          backgroundColor: "#eeeeee",
          borderWidth: 0,
          margin: 1,
          padding: 1,
          borderRadius: 2,
        }}
      >
        {tag}
      </button>
    </Link>
  )
}

export default Tag
