import React from "react"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TagArticles from "../components/tag-articles"
import ReadMoreButton from "../components/read-more-button"

const Tag = ({ pageContext, location }) => {
  const { tag, posts } = pageContext
  const siteTitle = "DDD ディストーション駆動開発"
  const siteDescription = ""
  const pageTitle = tag + "の記事一覧"

  return (
    <Layout location={location} title={siteTitle} description={siteDescription}>
      <SEO title={pageTitle} />
      <TagArticles tag={tag} posts={posts} />
      <ReadMoreButton />
      <Bio />
    </Layout>
  )
}

export default Tag
