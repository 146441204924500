import React from "react"

const Thumb = ({ hero }) => {

  if ( hero ){
    return(
      <img src={ hero } alt={ "blog thumbnail" } style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'top'}} />
    )
  }else{
    return(
      <img src={"/mustang.jpg"} alt={ "blog thumbnail" }  style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'top'}} />
    )
  }

}

export default Thumb
